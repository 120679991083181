.YellowBadge .MuiBadge-badge.MuiBadge-anchorOriginTopLeftRectangle.MuiBadge-colorPrimary.MuiBadge-dot {
    background-color: #F79F1F;
}

.RedBadge .MuiBadge-badge.MuiBadge-anchorOriginTopLeftRectangle.MuiBadge-colorPrimary.MuiBadge-dot {
    background-color: #EA2027;
}

.RedBadge .YellowBadge .MuiBadge-badge.MuiBadge-anchorOriginTopLeftRectangle.MuiBadge-colorPrimary.MuiBadge-dot {
    background-color: #F79F1F;
    top: -3px;
    left: -3px;
}
